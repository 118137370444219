var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Enter appropriate identifying information for each chemical used during Fundamental Skills. ")]),_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headings),function(head){return _c('th',{key:head.text},[_vm._v(" "+_vm._s(head.text)+" ")])}),0)]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Acetic Acid")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaAcA),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaAcA", $$v)},expression:"inputs.chemFormulaAcA"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmAcA),callback:function ($$v) {_vm.$set(_vm.inputs, "mmAcA", $$v)},expression:"inputs.mmAcA"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateAcA),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateAcA", $$v)},expression:"inputs.physStateAcA"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vAcA),callback:function ($$v) {_vm.$set(_vm.inputs, "vAcA", $$v)},expression:"inputs.vAcA"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cAcA),callback:function ($$v) {_vm.$set(_vm.inputs, "cAcA", $$v)},expression:"inputs.cAcA"}})],1)]),_c('tr',[_c('td',[_vm._v("Sodium Acetate")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaNaAc),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaNaAc", $$v)},expression:"inputs.chemFormulaNaAc"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmNaAc),callback:function ($$v) {_vm.$set(_vm.inputs, "mmNaAc", $$v)},expression:"inputs.mmNaAc"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateNaAc),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateNaAc", $$v)},expression:"inputs.physStateNaAc"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vNaAc),callback:function ($$v) {_vm.$set(_vm.inputs, "vNaAc", $$v)},expression:"inputs.vNaAc"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cNaAc),callback:function ($$v) {_vm.$set(_vm.inputs, "cNaAc", $$v)},expression:"inputs.cNaAc"}})],1)]),_c('tr',[_c('td',[_vm._v("Ammonium Chloride")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaNH4Cl),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaNH4Cl", $$v)},expression:"inputs.chemFormulaNH4Cl"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmNH4Cl),callback:function ($$v) {_vm.$set(_vm.inputs, "mmNH4Cl", $$v)},expression:"inputs.mmNH4Cl"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateNH4Cl),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateNH4Cl", $$v)},expression:"inputs.physStateNH4Cl"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vNH4Cl),callback:function ($$v) {_vm.$set(_vm.inputs, "vNH4Cl", $$v)},expression:"inputs.vNH4Cl"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cNH4Cl),callback:function ($$v) {_vm.$set(_vm.inputs, "cNH4Cl", $$v)},expression:"inputs.cNH4Cl"}})],1)]),_c('tr',[_c('td',[_vm._v("Ammonium Hydroxide")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaNH4OH),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaNH4OH", $$v)},expression:"inputs.chemFormulaNH4OH"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmNH4OH),callback:function ($$v) {_vm.$set(_vm.inputs, "mmNH4OH", $$v)},expression:"inputs.mmNH4OH"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateNH4OH),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateNH4OH", $$v)},expression:"inputs.physStateNH4OH"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vNH4OH),callback:function ($$v) {_vm.$set(_vm.inputs, "vNH4OH", $$v)},expression:"inputs.vNH4OH"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cNH4OH),callback:function ($$v) {_vm.$set(_vm.inputs, "cNH4OH", $$v)},expression:"inputs.cNH4OH"}})],1)]),_c('tr',[_c('td',[_vm._v("Hydrochloric Acid")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaHCl),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaHCl", $$v)},expression:"inputs.chemFormulaHCl"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmHCl),callback:function ($$v) {_vm.$set(_vm.inputs, "mmHCl", $$v)},expression:"inputs.mmHCl"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateHCl),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateHCl", $$v)},expression:"inputs.physStateHCl"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vHCl),callback:function ($$v) {_vm.$set(_vm.inputs, "vHCl", $$v)},expression:"inputs.vHCl"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cHCl),callback:function ($$v) {_vm.$set(_vm.inputs, "cHCl", $$v)},expression:"inputs.cHCl"}})],1)]),_c('tr',[_c('td',[_vm._v("Sodium Hydroxide")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaNaOH),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaNaOH", $$v)},expression:"inputs.chemFormulaNaOH"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmNaOH),callback:function ($$v) {_vm.$set(_vm.inputs, "mmNaOH", $$v)},expression:"inputs.mmNaOH"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateNaOH),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateNaOH", $$v)},expression:"inputs.physStateNaOH"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vNaOH),callback:function ($$v) {_vm.$set(_vm.inputs, "vNaOH", $$v)},expression:"inputs.vNaOH"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cNaOH),callback:function ($$v) {_vm.$set(_vm.inputs, "cNaOH", $$v)},expression:"inputs.cNaOH"}})],1)]),_c('tr',[_c('td',[_vm._v("pH 7 buffer")]),_c('td',{staticStyle:{"width":"150px"}},[_c('s-textarea',{attrs:{"outlined":""},model:{value:(_vm.inputs.chemFormulaBuff),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaBuff", $$v)},expression:"inputs.chemFormulaBuff"}})],1),_c('td',[_vm._v("N/A")]),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateBuff),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateBuff", $$v)},expression:"inputs.physStateBuff"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vBuff),callback:function ($$v) {_vm.$set(_vm.inputs, "vBuff", $$v)},expression:"inputs.vBuff"}})],1),_c('td',[_vm._v("N/A")])])])]),_c('p',{staticClass:"mt-5"},[_vm._v("Select all the glassware/equipment/instrumentation used in this lab.")]),_vm._l((_vm.equipmentOptions),function(option){return _c('p',{key:option.value,staticClass:"mb-0 mt-0"},[_c('v-checkbox',{staticClass:"ml-6 mb-n4 mt-0",attrs:{"value":option.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('stemble-latex',{attrs:{"content":option.value}})]},proxy:true}],null,true),model:{value:(_vm.inputs.equipment),callback:function ($$v) {_vm.$set(_vm.inputs, "equipment", $$v)},expression:"inputs.equipment"}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }